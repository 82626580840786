import React from 'react';
import { styled } from '@linaria/react';

function FooterSuccessClouds({
  baseDelay = 0,
}: {
  baseDelay?: number;
}) {
  // const [key, setKey] = React.useState(0);
  return (
    <Wrapper
      // onClick={() => setKey(key + 1)} key={key}
      style={{
        '--base-delay': `${baseDelay}ms`,
      }}
    >
      <Content>
        <p>
          <strong>Success!</strong>
        </p>
        <p>
          I just sent you a welcome email, which includes the “Jerica
          Starter Pack”, a collection of my most popular content. 😄
        </p>
      </Content>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 833 370"
        preserveAspectRatio="none"
      >
        <circle cx="131" cy="124" r="113" />
        <circle cx="257.5" cy="93.5" r="72.5" />
        <circle cx="250.5" cy="245.5" r="119.5" />
        <circle cx="370" cy="102" r="102" />
        <circle cx="411" cy="237" r="128" />
        <circle cx="484.5" cy="115.5" r="88.5" />
        <circle cx="540.5" cy="247.5" r="84.5" />
        <circle cx="596" cy="122" r="111" />
        <circle cx="134.5" cy="291.5" r="78.5" />
        <circle cx="671.5" cy="234.5" r="130.5" />
        <circle cx="717" cy="86" r="65" />
        <circle cx="64.5" cy="224.5" r="64.5" />
        <circle cx="760" cy="151" r="73" />
      </Svg>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1.5rem;
  max-width: 400px;
  animation: enter 1000ms both cubic-bezier(0, 0.25, 0.23, 1.01);
  animation-delay: var(--base-delay);
  transform-origin: 50% 50%;
  will-change: transform;

  @keyframes enter {
    from {
      opacity: 0;
      transform: translateY(50%) scale(0.8);
    }
  }
`;

const Content = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 52px;
  /* Optical alignment */
  padding-bottom: 8px;
  text-align: center;
  color: black;
  animation: fadeIn 800ms both;
  animation-delay: calc(var(--base-delay) + 1000ms);

  p:not(:last-child) {
    margin-bottom: 0.75em;
  }
`;

const Svg = styled.svg`
  display: block;
  width: 100%;
  height: 10.9375rem;
  --curve: cubic-bezier(0.17, 0, 0.21, 1.01);

  circle {
    fill: var(--color-adaptive-white);
    /* Each circle has its own origin */
    transform-box: fill-box;

    &:nth-child(1) {
      animation: growOne 1300ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 0ms);
      transform-origin: 100% 100%;
      @keyframes growOne {
        from {
          transform: scale(0.7);
        }
      }
    }
    &:nth-child(2) {
      animation: growTwo 700ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 100ms);
      transform-origin: 90% 100%;
      @keyframes growTwo {
        from {
          transform: scale(0.6);
        }
      }
    }
    &:nth-child(3) {
      animation: growThree 1200ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 0ms);
      transform-origin: 90% 0%;
      @keyframes growThree {
        from {
          transform: scale(0.8);
        }
      }
    }
    &:nth-child(4) {
      animation: growFour 1200ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 0ms);
      transform-origin: 60% 100%;
      @keyframes growFour {
        from {
          transform: scale(0.5);
        }
      }
    }
    &:nth-child(5) {
      animation: growFive 1600ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 0ms);
      transform-origin: 50% 0%;
      @keyframes growFive {
        from {
          transform: scale(0.8);
        }
      }
    }
    &:nth-child(6) {
      animation: growSix 950ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 100ms);
      transform-origin: 40% 100%;

      @keyframes growSix {
        from {
          transform: scale(0.65);
        }
      }
    }
    &:nth-child(7) {
      animation: growSeven 750ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 150ms);
      transform-origin: 40% 0%;

      @keyframes growSeven {
        from {
          transform: scale(0.75);
        }
      }
    }
    &:nth-child(8) {
      animation: growEight 1650ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 0ms);
      transform-origin: 40% 100%;

      @keyframes growEight {
        from {
          transform: scale(0.85);
        }
      }
    }
    &:nth-child(9) {
      animation: growNine 850ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 0ms);
      transform-origin: 100% 0%;

      @keyframes growNine {
        from {
          transform: scale(0.65);
        }
      }
    }
    &:nth-child(10) {
      animation: growTen 1680ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 0ms);
      transform-origin: 10% 0%;

      @keyframes growTen {
        from {
          transform: scale(0.75);
        }
      }
    }
    &:nth-child(11) {
      animation: growEleven 1225ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 0ms);
      transform-origin: 20% 80%;

      @keyframes growEleven {
        from {
          transform: scale(0.5);
        }
      }
    }
    &:nth-child(12) {
      animation: growTwelve 1225ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 300ms);
      transform-origin: 100% 50%;

      @keyframes growTwelve {
        from {
          transform: scale(0.25);
        }
      }
    }
    &:nth-child(13) {
      animation: growThirteen 725ms both var(--curve);
      animation-delay: calc(var(--base-delay) + 50ms);
      transform-origin: 0% 70%;

      @keyframes growThirteen {
        from {
          transform: scale(0.65);
        }
      }
    }
  }
`;

export default FooterSuccessClouds;
