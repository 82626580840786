'use client';

import * as React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import useSound from '@/hooks/use-sound';

import IconWrapper from './IconWrapper';

export function IconTwitter({
  size = 20,
  isBooped,
}: {
  size?: number;
  isBooped: boolean;
}) {
  const style = useSpring({
    transform: isBooped ? 'rotate(10deg)' : 'rotate(0deg)',
    config: {
      tension: 300,
      friction: 11,
    },
    delay: 120,
  });

  const path = isBooped
    ? `M6.1462 7.68335C8.0503 8.65217 11.7298 8.68686 11.7298 8.68686C11.7298 8.68686 11.7041 6.42253 11.9871 5.75353C12.2702 5.08452 13.248 2.97458 16.2327 3.00031C19.2175 3.02604 19.7064 4.5699 19.7064 4.5699L22 2.30908L20.5 5.75353L23.9743 5.30908L20.993 7.09154V8.68686C20.993 9.53598 22 21.8679 7.5614 21.7839C3.13567 21.7582 1 18.7512 1 18.7512C1 18.7512 2.80117 18.6225 4.47368 18.288C6.1462 17.9535 6.99532 16.5126 6.99532 16.5126C6.99532 16.5126 1.84156 15.3255 1.54035 8.89271C1.41264 6.16522 2.38947 4.28686 2.38947 4.28686C2.38947 4.28686 4.38345 6.78645 6.1462 7.68335Z`
    : `M11.7298 8.68678C11.7298 8.68678 8.0503 8.6521 6.1462 7.68327C4.38345 6.78637 2.38947 4.28678 2.38947 4.28678C2.38947 4.28678 1.41264 6.16515 1.54035 8.89263C1.84156 15.3254 6.99532 16.5125 6.99532 16.5125C6.99532 16.5125 6.1462 17.9534 4.47368 18.288C2.80117 18.6225 1 18.7511 1 18.7511C1 18.7511 3.13567 21.7581 7.5614 21.7839C22 21.8678 20.993 9.53591 20.993 8.68678C20.993 7.83766 20.993 7.09146 20.993 7.09146L22.9743 3.30901L20.5 5.75345L23 3.30901L19.7064 4.56982C19.7064 4.56982 19.2175 3.02596 16.2327 3.00023C13.248 2.9745 12.2702 5.08445 11.9871 5.75345C11.7041 6.42245 11.7298 8.68678 11.7298 8.68678Z`;

  return (
    <BirdSvg
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d={path}
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BirdSvg>
  );
}

function WrappedIconTwitter(props: { size?: number }) {
  const [playBite] = useSound('/sounds/bite.mp3', 0.4);

  const timeoutId = React.useRef<number | null>(null);

  const playBiteAfterDelay = () => {
    timeoutId.current = window.setTimeout(playBite, 290);
  };

  React.useEffect(() => {
    return () => {
      if (timeoutId.current) {
        window.clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <IconWrapper
      alt="Jerica's Twitter profile"
      {...props}
      href="https://bsky.app/profile/joshwcomeau.com"
      onMouseEnter={() => {
        playBiteAfterDelay();
      }}
    >
      {({ isBooped }) => <IconTwitter isBooped={isBooped} />}
    </IconWrapper>
  );
}

const BirdSvg = styled(animated.svg)`
  display: block;
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  overflow: visible;

  path {
    transition: d 200ms cubic-bezier(0.6, -0.4, 1, 0.79);
    stroke: currentColor;
  }
`;

export default WrappedIconTwitter;
