import { sampleOne } from '@/utils';

export const generateFirstName = () => {
  return sampleOne([
    'Maria',
    'Nushi',
    'Mohammed',
    'Jose',
    'Wei',
    'Ahmed',
    'Yan',
    'Ali',
    'John',
    'David',
    'Abdul',
    'Anna',
    'Michael',
    'Juan',
    'Mary',
    'Jean',
    'Robert',
    'Daniel',
    'Luis',
    'Carlos',
    'James',
    'Antonio',
    'Joseph',
    'Elena',
    'Francisco',
    'Hong',
    'Marie',
    'Peter',
    'Fatima',
    'Richard',
    'Paul',
    'Olga',
    'Rosa',
    'William',
    'Elizabeth',
    'Patricia',
    'Mario',
    'Luigi',
    'Patrick',
    'Svetlana',
    'Natalya',
    'Sarah',
    'Samuel',
    'Ndeye',
  ]);
};

export const generateLastName = () => {
  return sampleOne([
    'Wang',
    'Smith',
    'Johnson',
    'Williams',
    'Devi',
    'Ivanov',
    'Kim',
    'Ali',
    'García',
    'Müller',
    'da Silva',
    'Singh',
    'Wilson',
    'Martinez',
    'Anderson',
    'Taylor',
    'Tesfaye',
    'Nguyen',
    'González',
    'Deng',
    'Lopez',
    'Rodriguez',
    'Lee',
    'Moore',
    'Hernandez',
    'Jackson',
    'Hansen',
    'Chen',
    'Schmidt',
    'Gomez',
    'Reyes',
    'Zhao',
    'Hoffmann',
    'Kumar',
    'Tremblay',
    'Li',
    'Wu',
    'Ahmed',
    'Jiang',
  ]);
};

export const generateFullName = () => {
  let firstName = generateFirstName();
  let lastName = generateLastName();
  let prefix, initial;

  // 25% chance of middle initial
  if (Math.random() <= 0.25) {
    // prettier-ignore
    initial = sampleOne(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']) + '.';
  }

  // 5% of Dr. prefix
  if (Math.random() <= 0.05) {
    prefix = 'Dr.';
  }

  return [prefix, firstName, initial, lastName]
    .filter(Boolean)
    .join(' ');
};

export const generateEmailDomain = () => {
  return sampleOne([
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'yahoo.co.uk',
    'live.com',
    'web.de',
    'libero.it',
    'comcast.net',
  ]);
};

export const generateEmail = () => {
  const firstName = generateFirstName().toLowerCase();
  const domain = sampleOne([
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'yahoo.co.uk',
    'live.com',
    'web.de',
    'libero.it',
    'comcast.net',
    generateLastName().toLowerCase() + '.dev',
  ]);

  return `${firstName}@${domain}`;
};

export const generateHandle = (name?: string) => {
  // if we provide a name, there's a 75% chance we'll use their first name plus some numbers
  if (name && Math.random() <= 0.75) {
    return `${name.split(' ')[0]}${Math.floor(Math.random() * 100)}`;
  }

  // Otherwise, make something up from scratch
  return sampleOne(COOL_HANDLES);
};

export const generateLocation = (): [string, string] => {
  const [city, country] = sampleOne(LOCATIONS);

  return [city, country];
};
export const generateJob = (): string => {
  return sampleOne(JOBS);
};

const LOCATIONS = [
  ['Buenos Aires', 'AR'],
  ['Sydney', 'AU'],
  ['São Paulo', 'BR'],
  ['Ottawa', 'CA'],
  ['Toronto', 'CA'],
  ['Kinshasa', 'CD'],
  ['Chongqing', 'CN'],
  ['Frankfurt', 'DE'],
  ['Cairo', 'EG'],
  ['Paris', 'FR'],
  ['London', 'GB'],
  ['Hong Kong', 'HK'],
  ['Jakarta', 'ID'],
  ['Delhi', 'IN'],
  ['Mumbai', 'IN'],
  ['Baghdad', 'IQ'],
  ['Tehran', 'IR'],
  ['Tokyo', 'JP'],
  ['Seoul', 'KR'],
  ['Kuala Lumpur', 'MY'],
  ['Mexico City', 'MX'],
  ['Amsterdam', 'NL'],
  ['Karachi', 'PK'],
  ['Singapore', 'SG'],
  ['Barcelona', 'SP'],
  ['Istanbul', 'TR'],
  ['Chicago', 'US'],
  ['New York', 'US'],
  ['San Francisco', 'US'],
];

const COOL_HANDLES = [
  'ExpertCoder99',
  'eyeofthestorm',
  'peaceandlove42069',
  'TransRightsAreHumanRights',
  'taylorswiftfan2',
  'porterrobinsonfan',
  'gohabsgo',
  'modularmusic',
  'webdevwizard',
  'codingisfun',
  'javascriptjammer',
  'envelopegenerator',
  'fractal161',
  'vandweller',
  'solarpowered',
  'bengalragdoll',
  'lychee1213',
  'luckycat',
  'dogplayingtetris',
  'pixeljosh',
  'boxmodel',
  'stickjoy',
  'blanketfort',
  'blinkofaneye',
  'cheerleader',
  'bubblepop',
  'nostalgic9',
  'virtualmotion',
  'solarsurfer',
  'somethingcorporate',
  'andsoifall',
  'sunsetdreams',
  'blasphemous',
  'prettysunset',
  'foxstevenson',
  'pianowiggle',
  'timhenson',
  'KentuckyFriedDodds',
  'supersawpluck',
  'distortedsines',
  'alltheharmonics',
];

const JOBS = [
  'Software Developer',
  'Crypto Grifter',
  'Freelancer',
  'Web Designer',
  '3D Artist',
  'Producer',
  'Product Manager',
  'Engineering Manager',
  'Data Scientist',
  'Teacher',
  'Student',
  'Street Performer',
  'Lead Guitarist',
  'Intern',
  'Staff Software Engineer',
  'Principal Engineer',
  'Senior Eng Manager',
  'Director of Engineering',
  'VP of Engineering',
  'CEO',
  'CTO',
  'Accountant',
  'HR Manager',
  'Recruiter',
  'Director of Sales',
  'Sales Manager',
  'QA Engineer',
  'Barista',
  'Tattoo Artist',
  'Graphic Designer',
  'Illustrator',
  'Funemployed',
  'Influencer',
];
